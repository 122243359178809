import { differenceInDays, format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'

export const formatTime = (date: string) => {
  if (date) {
    const currentDate = new Date()
    const parsedDate = parseISO(date)
    const daysDifference = Math.floor(
      (currentDate.getTime() - parsedDate.getTime()) / (1000 * 60 * 60 * 24),
    )
    if (daysDifference === 0) {
      return 'сегодня'
    }

    const differenceInMonths = Math.floor(daysDifference / 30)

    if (daysDifference < 30) {
      return `${daysDifference} ${pluralizeDaysAgo(daysDifference)} назад`
    } else {
      return `${differenceInMonths} ${pluralizeMouthAgo(differenceInMonths)} назад`
    }
  }
}

export const formatTimeDaysAgo = (date: string, no_calendar?: boolean) => {
  if (!date) {
    return ''
  }

  const parsedDate = parseISO(date)
  const currentDate = new Date()
  const daysAgo = differenceInDays(currentDate, parsedDate)
  const formattedDate = format(parsedDate, 'dd MMM', { locale: ru })
  let result = ''

  if (!daysAgo) {
    result = no_calendar ? 'сегодня' : `${formattedDate} (сегодня)`
  } else {
    result = no_calendar
      ? `${daysAgo} ${pluralizeDaysAgo(daysAgo)} назад`
      : `${formattedDate} (${daysAgo} ${pluralizeDaysAgo(daysAgo)} назад)`
  }

  return result
}

export const formatTimeFeature = (dateString: string): string => {
  if (!dateString) return ''
  const inputDate = parseISO(dateString)
  const zonedDate = utcToZonedTime(inputDate, 'Europe/Moscow')

  const formattedDate = format(zonedDate, 'dd MMM в HH:mm', { locale: ru })

  return formattedDate
}

export const formatTimeExactDate = (date: string) => {
  if (date) {
    const parsedDate = parseISO(date)
    const formattedDate = format(parsedDate, 'dd MMM yyyy', { locale: ru })
    return formattedDate
  }

  return ''
}

export const convertTo24HourFormat = (dateTimeString: string) => {
  const date = new Date(dateTimeString)
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formattedHours = hours < 10 ? '0' + hours : '' + hours
  const formattedMinutes = minutes < 10 ? '0' + minutes : '' + minutes

  return `${formattedHours}:${formattedMinutes}`
  //output 12:52
}

const pluralizeDaysAgo = (days: number) => {
  const lastDigit = days % 10
  if (days >= 11 && days <= 20) {
    return 'дней'
  } else if (lastDigit === 1) {
    return 'день'
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return 'дня'
  } else {
    return 'дней'
  }
}

const pluralizeHoursAgo = (hours: number) => {
  if (hours === 1) {
    return 'час'
  } else if (hours >= 2 && hours <= 4) {
    return 'часа'
  } else {
    return 'часов'
  }
}
const pluralizeMouthAgo = (hours: number) => {
  if (hours === 1) {
    return 'месяц'
  } else if (hours >= 2 && hours <= 4) {
    return 'месяца'
  } else {
    return 'месяцев'
  }
}
